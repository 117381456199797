.container {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  background: none;
  color: var(--black);
  padding: 60px;
}

.card {
  position: relative;
  background-color: var(--white);
  border: solid 1px var(--purple);
  padding: 16px 24px;
}

.description {
  font-size: 16px;
  font-weight: 500;
  line-height: 20px;
  margin-block-end: 1.875rem;
}

.wrapper {
  display: flex;
  align-items: center;
}

.image {
  width:52px;
  height: 52px;
  border-radius: 100%;
  overflow: hidden;
  background-color: var(--light-gray);
  margin-inline-end: 12px;
  flex-shrink: 0;
}

.name {
  font-weight:700;
  font-size: 20px;
  line-height: 18px;
  margin-bottom: 2px;
}

.position {
  font-size: 16px;
  line-height: 16px;
  margin-block-end: 0;
  font-weight: 400;
  text-transform: uppercase;
}

.rating {
  color: var(--light-gray);
  font-size: 1.4rem;
  margin-bottom: 4px;
}
.date {
  color: var(--light-gray);
  font-size: 1.4rem;
  margin: 0;
  text-align: right;
}