@import "../../styles/mixins.scss";

.container {
  height: 64px;
  position: relative;
  display: flex;
  justify-content: center;
  background-color: white;
  color: black;
  font-family: "CheddarGothic-sans", Arial;
  font-size: 2rem;
  @include xl {
    font-size: 1rem;
  }
  a {
    display: flex;
    justify-content: center;
    align-items: center;
    & > span {
      align-self: center;

      & > span {
        color: #55b103;
      }
    }
  }

  img {
    max-width: 64px;
  }

  .close {
    position: absolute;
    right: 48px;
    font-size: 2rem;
    top: 50%;
    transform: translateY(-50%);
    cursor: pointer;
  }

  @include md {
    display: none;
  }
}
