@import "../../styles/mixins.scss";

.modal {
  display: none;
  &_show {
    display: flex;
    justify-content: center;
    align-items: center;
    .overlay {
      background-color: #000000d1;
      position: fixed;
      left: 0;
      top: 0;
      min-height: 100vh;
      min-width: 100vw;
      z-index: 1000;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
  &__wrap {
    max-width: 800px;
    z-index: 10001;
    background-color: var(--white);
    color: var(--black);
    border-radius: 16px;
    box-shadow: 0 1px 4px 0 rgb(46 61 73 / 10%);
    position: relative;
    padding: 40px;
    animation: toBottom .8s;
  }
  &__close-btn {
    position: absolute;
    top: 0px;
    right: 10px;
    font-size: 30px;
    cursor: pointer;
  }
}

.block {
  display: flex;
  align-items: center;
  gap: 20px;
  @include md {
    flex-direction: column;
  }
  &__img {
    max-width: 420px;
    width: 100%;
  }
  &__title {
    font-size: 2.2rem;
    line-height: 1;
    text-transform: uppercase;
    margin-bottom: 10px;
    font-family: "CheddarGothic-sans", Arial;
    & > span {
      color: #55b103;
    }
    @include md {
      text-align: center;
      margin-bottom: 10px;
    }
  }
  &__btn {
    font-family: "Poppins", Arial;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    background-color: #55b103;
    padding: 5px 5px;
    color: var(--white);
    font-size: 30px;
    font-weight: bold;
    width: 100%;
    text-transform: uppercase;
    transition: 0.2s;
    cursor: pointer;
  }
}


@keyframes toBottom {
    0% {
        transform: translateY(-50vh);
    }
    100% {
        transform: translateY(0);
    }
}