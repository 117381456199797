@import "./mixins.scss";

.intro {
  &__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 54px;
    padding: 178px 0 108px;
    @include lg {
      padding: 108px 0 108px;
    }
    @include md {
      padding: 60px 0;
      text-align: center;
    }
    h1 {
      @include lg {
        font-size: 48px;
        line-height: 48px;
      }
      @include sm {
        font-size: 38px;
        line-height: 38px;
      }
    }
  }
  &__desc {
    max-width: 852px;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 32px;
  }
}

.services {
  padding: 60px 0 120px;
  background-color: var(--white);
  color: var(--black);
  &__title {
    font-size: 64px;
    font-weight: 900;
    line-height: 64px;
    margin-bottom: 16px;
    @include lg {
      font-size: 46px;
      line-height: 46px;
    }
  }
  &__desc {
    max-width: 756px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 46px;
  }
  &__wrap {
    display: flex;
    flex-wrap: wrap;
    gap: 60px 40px;
  }
  &__item {
    align-self: flex-start;
    position: relative;
    padding: 32px;
    width: calc(100% / 3 - 40px);
    border: solid 1px var(--black);
    @include lg {
      width: calc(100% / 2 - 40px);
    }
    @include sm {
      width: 100%;
    }
    h3 {
      font-size: 40px;
      font-weight: 900;
      line-height: 48px;
      margin-bottom: 26px;
      @include lg {
          font-size: 32px;
          line-height: 32px;
      }
    }
    p {
      font-size: 16px;
      line-height: 20px;
      font-weight: 500;
      margin-bottom: 32px;
    }
    ul {
      list-style-type: none;
      padding-left: 0;
      li {
        display: flex;
        align-items: center;
        gap: 8px;
        font-size: 16px;
        font-weight: 700;
        line-height: 19px;
        &:not(:last-child) {
          margin-bottom: 16px;
        }
        svg {
          width: 24px;
          height: 24px;
        }
      }
    }
    &-star {
      width: 50px;
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
    }
  }
}
