@import "./mixins.scss";

.intro {
  &__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 54px;
    padding: 178px 0 108px;
    @include lg {
      padding: 108px 0 108px;
    }
    @include md {
      padding: 60px 0;
      text-align: center;
    }
    h1 {
      @include lg {
        font-size: 48px;
        line-height: 48px;
      }
      @include sm {
        font-size: 38px;
        line-height: 38px;
      }
    }
  }
  &__img {
    @include md {
      display: none;
    }
  }
  &__desc {
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 32px;
  }
  &__btn {
    font-size: 24px;
    padding: 16px 80px;
  }
}

.code {
  background-color: var(--white);
  padding-block-start: 90px;
  color: var(--black);
  &__wrap {
    background-image: url("../assets/code/bg.png");
    background-repeat: no-repeat;
    background-position: bottom right;
    @include xl {
      background-image: initial;
    }
    @include md {
      text-align: center;
    }
  }
  &__line {
    position: relative;
    width: 100%;
    border-bottom: solid 1px var(--black);
    margin-bottom: 22px;
    &-star {
      position: absolute;
      left: 0;
      top: 0;
      transform: translate(-50%, -50%);
      width: 49px;
    }
  }
  &__desc {
    padding-right: 30px;
    color: var(--black);
    font-size: 24px;
    font-weight: 700;
    @include lg {
      padding-right: 0;
      margin-bottom: 0;
    }
    &-wrap {
      display: flex;
      gap: 26px;
      margin-bottom: 90px;
      @include lg {
        flex-direction: column-reverse;
      }
    }
  }
  &__logo {
    font-size: 200px;
    color: var(--black);
    font-weight: 900;
    @include xl {
      font-size: 80px;
    }
  }
  &-types {
    display: flex;
    gap: 0 114px;
    padding-bottom: 80px;
    max-width: 820px;
    flex-wrap: wrap;
    justify-content: center;
    @include sm {
      padding-bottom: 40px;
    }
    @include xs {
      gap: 0 64px;
    }
    &__item {
      height: 182px;
      width: 182px;
      display: flex;
      align-items: center;
      justify-content: center;
      border: 1px solid var(--black);
      font-size: 32px;
      font-weight: 900;
      transform: rotate(45deg);
      transition: background-color 0.3s;
      @include lg {
        height: 140px;
        width: 140px;
        font-size: 26px;
      }
      @include sm {
        height: 110px;
        width: 110px;
      }
      @include xs {
        font-size: 22px;
        height: 80px;
        width: 80px;
      }
      & > div {
        transform: rotate(-45deg);
      }
      &:nth-last-child(1),
      &:nth-last-child(2) {
        transform: rotate(45deg) translate(-22px, -22px);
      }
      @media (max-width: 700px) {
        &:nth-child(3) {
          transform: rotate(45deg) translate(38%, 38%);
        }
        &:nth-child(4) {
          transform: rotate(45deg) translate(38%, 38%);
        }
        &:last-child {
          transform: rotate(45deg) translate(-86%, -86%);
        }
      }
      &:hover {
        background-color: var(--purple);
        color: var(--white);
      }
    }
  }
}

.why {
  padding: 84px 0;
  @include md {
    padding: 40px 0;
  }

  &__wrap {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    @include xl {
      gap: 60px;
      flex-direction: column;
    }
  }
  &__title {
    margin-bottom: 18px;
  }
  &-advantages {
    display: flex;
    gap: 64px var(--spacing);
    flex-wrap: wrap;
    position: relative;
    border-left: solid 1px var(--light-purple);
    --spacing: 6px;
    padding: 60px 0 60px 46px;
    @include md {
      padding: 30px 0 30px 26px;
    }
    &__star {
      position: absolute;
      left: 0;
      top: 50%;
      width: 48px;
      transform: translate(-50%, -50%);
      color: var(--light-purple);
    }

    &__item {
      display: flex;
      align-items: flex-end;
      position: relative;
      width: calc(50% - var(--spacing));

      p {
        max-width: 276px;
        transform: translateX(-40px);
        position: relative;
        font-weight: 900;
        font-size: 32px;
        line-height: 38px;
        margin-bottom: 0;
        @include md {
          font-size: 26px;
          line-height: 26px;
        }
        @include sm {
          font-size: 18px;
          line-height: 20px;
        }
      }
    }
    &__num {
      font-size: 200px;
      line-height: 155px;
      font-weight: 900;
      color: var(--black);
      text-shadow: 0px 0px 5px var(--light-purple);
      @include lg {
        font-size: 180px;
      }
      @include md {
        font-size: 160px;
      }
      @include sm {
        font-size: 120px;
        line-height: 100px;
      }
    }
  }
  &-statistics {
    max-width: 580px;
    @include lg {
      max-width: initial;
      text-align: center;
    }
    &__wrap {
      display: flex;
      gap: 0 80px;
      justify-content: center;
      flex-wrap: wrap;
      margin-bottom: 30px;
      @include sm {
        text-align: center;
        gap: 40px;
      }
    }
    &__item {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 246px;
      width: 246px;
      border: solid 1px var(--white);
      border-radius: 50%;
      text-align: center;
      @include md {
        height: 200px;
        width: 200px;
      }
    }
    &__value {
      font-size: 80px;
      line-height: 80px;
      font-weight: 900;
      margin-bottom: 0;
      @include md {
        font-size: 60px;
        line-height: 60px;
      }
    }
    &__name {
      font-size: 24px;
      font-weight: 900;
      margin-bottom: 0;
    }
    &__icon {
      position: absolute;
      width: 50px;
      height: 50px;
      @include md {
        width: 40px;
        height: 40px;
      }
      &_top-left {
        top: 0;
        left: 0;
        transform: translate(38%);
      }
      &_top-right {
        top: 0;
        right: 0;
        transform: translate(-38%);
      }
      &_bottom-right {
        bottom: 0;
        right: 0;
        transform: translate(-38%);
      }
    }
  }
}

.testominals {
  background-color: var(--white);
  padding-block-start: 60px;

  &_bg {
    background-image: url("../assets/testominials/bg.png");
    background-position: bottom left;
    background-repeat: no-repeat;
    padding-bottom: 46px;
    @include md {
      background-position: bottom center;
      padding-bottom: 360px;
    }
  }
  &__wrap {
    margin-bottom: 80px;
  }
  &__title {
    position: relative;
    color: var(--black);
    text-align: right;
    border-bottom: solid 1px var(--purple);
    margin-bottom: 32px;
    padding-bottom: 6px;
    &-icon {
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate(-50%, 50%);
      color: var(--purple);
      width: 50px;
    }
  }
  &__desc {
    width: 50%;
    margin-left: auto;
    margin-bottom: 40px;
    padding-left: 10px;
    color: var(--black);
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    @include md {
      width: 100%;
      text-align: center;
    }
  }
}

.art {
  padding-top: 80px;
  padding-bottom: 80px;
  @include lg {
    padding-top: 40px;
    padding-bottom: 40px;
  }
  &__slider {
    position: relative;
    border: solid 1px var(--white);
    padding: 40px;
    &-star {
      width: 50px;
      position: absolute;
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
    }
  }

  &__bg {
    background-image: url("../assets/art/bg.png");
    background-position: bottom right;
    background-repeat: no-repeat;
    padding-bottom: 80px;
    @include xl {
      background-image: initial;
    }
  }
  &__title {
    margin-bottom: 16px;
  }
  &__star {
    position: absolute;
    left: 0;
    bottom: 0;
    transform: translate(-50%, 50%);
  }
  &__desc {
    max-width: 720px;
    p {
      font-size: 16px;
      font-weight: 700;
    }
  }
  &__wrap {
    position: relative;
    padding-left: 36px;
    padding-bottom: 40px;
    border-left: solid 1px var(--white);
  }
}

.web3 {
  padding: 80px 0;
  color: var(--black);
  background-color: var(--white);
  background-image: url("../assets/web3/bg.png");
  background-repeat: no-repeat;
  background-position: right bottom;
  @include lg {
    background-image: initial;
    padding: 40px 0;
  }
  &__star {
    &-l {
      width: 50px;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
    &-r {
      width: 50px;
      position: absolute;
      right: 0;
      top: 0;
      transform: translate(50%);
    }
  }
  &__text {
    max-width: 920px;
    position: relative;
    padding-left: 36px;
    border-left: solid 1px var(--black);
    padding-bottom: 60px;
  }
  &__title {
    font-weight: 900;
    margin-bottom: 16px;
  }
  &__desc {
    max-width: 720px;
    font-weight: 700;
    font-size: 16px;
    line-height: 20px;
  }
  &__wrap {
    margin-top: 40px;
    margin-bottom: 40px;
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
    @include lg {
      justify-content: center;
      gap: 60px;
    }
  }
  &__item {
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    transform: rotate(45deg);
    height: 200px;
    width: 200px;
    border: solid 1px var(--black);
    transition: 0.3s background-color;
    @include xxl {
      height: 160px;
      width: 160px;
    }
    @include lg {
      height: 110px;
      width: 110px;
    }
    @include xs {
      height: 95px;
      width: 95px;
    }
    &:hover {
      color: var(--white);
      background-color: var(--purple);
    }
    &:last-child {
      @media (max-width: 510px) {
        transform: rotate(45deg) translate(-45%, -45%);
      }
    }
    h3 {
      font-size: 26px;
      line-height: normal;
      font-weight: 900;
      transform: rotate(-45deg);
      margin-bottom: 0;
      @include xxl {
        font-size: 18px;
      }
      @include xs {
        font-size: 16px;
      }
    }
  }
}

.portfolio {
  padding-block-start: 80px;
  padding-block-end: 80px;
  @include lg {
    padding-block-start: 40px;
    padding-block-end: 40px;
  }
  &__title {
    text-align: right;
    margin-bottom: 16px;
  }
  &__img {
    max-width: 560px;
  }
  &__wrap {
    display: flex;
    gap: 30px;
    @include lg {
      flex-direction: column-reverse;
    }
  }
  &__star {
    width: 50px;
    position: absolute;
    right: 0;
    bottom: 0;
    transform: translate(50%, 50%);
  }
  &__text {
    position: relative;
    margin-top: 80px;
    padding-bottom: 20px;
    padding-right: 36px;
    border-right: solid 1px var(--white);
    @include lg {
      margin-top: 0px;
    }
  }
  &__desc {
    font-size: 16px;
    font-weight: 700;
    line-height: 20px;
  }
  &__slider {
    position: relative;
    border: solid 1px var(--white);
    padding: 40px;
    @include sm {
      padding: 16px;
    }
    &-star {
      width: 50px;
      position: absolute;
      left: 0;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
  }
}
