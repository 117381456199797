@import "./mixins.scss";

.intro {
  &__wrap {
    position: relative;
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 54px;
    padding: 178px 0 108px;
    @include lg {
      padding: 108px 0 108px;
    }
    @include md {
      padding: 60px 0;
      text-align: center;
    }
    h1 {
      @include lg {
        font-size: 48px;
        line-height: 48px;
      }
      @include sm {
        font-size: 38px;
        line-height: 38px;
      }
    }
  }
  &__desc {
    max-width: 852px;
    font-size: 20px;
    font-weight: 900;
    margin-bottom: 32px;
  }
}

.leaders {
  background-color: var(--white);
  color: var(--black);
  padding: 56px 0;
  &__title {
    max-width: 747px;
    font-size: 64px;
    font-weight: 900;
    line-height: 77px;
    margin-bottom: 16px;
    @include lg {
      font-size: 46px;
      line-height: 46px;
    }
  }
  &__desc {
    max-width: 747px;
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;
    margin-bottom: 100px;
  }
}

.team {
  display: flex;
  gap: 0 114px;
  padding-bottom: 80px;
  max-width: 820px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  @include sm {
    padding-bottom: 0px;
  }
  @include xs {
    gap: 0 64px;
  }
  &__info {
    display: flex;
    position: absolute;
    left: 0;
    top: 0;
    color: var(--white);
    transition: opacity 0.4s;
    opacity: 0;
    background-color: #0806049c;
    width: 100%;
    height: 100%;
    text-align: center;
    
    &-text {
      display: flex;
      justify-content: center;
      align-items: center;
      transform: rotate(-45deg);
      h3 {
        font-size: 24px;
        font-weight: 600;
        line-height: 24px;
        margin-bottom: 4px;
        @include lg {
          font-size: 18px;
          line-height: 18px;
        }
        @include xs {
          font-size: 12px;
          line-height: 12px;
        }
      }
      p {
        font-size: 20px;
        font-weight: 900;
        margin-bottom: 0;
        @include lg {
          font-size: 16px;
          line-height: 16px;
        }
        @include sm {
          font-size: 12px;
          font-weight: 500;
        }
        @include xs {
          font-size: 9px;
          line-height: 18px;
        }
      }
    }
  }

  &__item {
    display: flex;
    height: 182px;
    width: 182px;
    overflow: hidden;
    cursor: pointer;
    position: relative;
    align-items: center;
    justify-content: center;
    border: 1px solid var(--black);
    font-size: 32px;
    font-weight: 900;
    transform: rotate(45deg);
    transition: background-color 0.3s;
    text-transform: uppercase;
    @include lg {
      height: 140px;
      width: 140px;
      font-size: 20px;
    }
    @include sm {
      height: 110px;
      width: 110px;
    }
    @include xs {
      font-size: 22px;
      height: 80px;
      width: 80px;
    }
    &-img {
      transform: rotate(-45deg);
      img {
        max-width: 266px;
        @include lg {
          max-width: 204px;
        }
        @include sm {
          max-width: 158px;
        }
        @include xs {
          max-width: 120px;
        }
      }
    }
    &:nth-last-child(1),
    &:nth-last-child(2) {
      transform: rotate(45deg) translate(-22px, -22px);
    }
    @media (max-width: 700px) {
      &:nth-child(3) {
        transform: rotate(45deg) translate(38%, 38%);
      }
      &:nth-child(4) {
        transform: rotate(45deg) translate(38%, 38%);
      }
      &:last-child {
        transform: rotate(45deg) translate(-86%, -86%);
      }
    }
    &:hover {
      .team__info {
        opacity: 1;
      }
    }
  }
}

.join {
  text-align: center;
  padding: 60px 0;
  &__title {
    font-size: 64px;
    font-weight: 900;
    line-height: 77px;
    margin-bottom: 16px;
    @include lg {
      font-size: 46px;
      line-height: 46px;
    }
  }
  &__desc {
    font-size: 20px;
    font-weight: 600;
    line-height: 24px;

    margin: 0 auto 26px auto;
    max-width: 880px;
  }
  &__btn {
    padding: 16px 80px;
  }
}
