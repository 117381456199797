.btn {
    display: inline-block;
    background: linear-gradient(90deg, #316cb0 0%, #7029cb 100%);
    border-radius: 40px;
    padding: 12px 32px;
    font-weight: 900;
    font-size: 16px;
    cursor: pointer;
    transition: opacity 0.3s;
    &:hover {
        opacity: 0.8;
    }
}
