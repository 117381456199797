.slider {
    
    &__card {
      padding-left: 50px;
      padding-right: 50px;
    }
    &__img {
        margin-bottom: 16px;
    }
    &__title {
        font-size: 20px;
        font-weight: 700;
        margin-bottom: 8px;
        line-height: 24px;
    }
    &__desc {
        font-size: 20px;
        font-weight: 400;
        margin-bottom: 0;
    }
}
