@import "../../styles/mixins.scss";
.form {
    position: relative;
    padding: 36px 40px;
    border: solid 1px var(--black);
    &__star {
        width: 50px;
        position: absolute;
        right: 0;
        top: 0;
        transform: translate(50%, -50%);
    }
    &__group {
        width: 100%;
        margin-block-end: 26px;
        &_flex {
            display: flex;
            justify-content: space-between;
            gap: 20px;
            @include sm {
                flex-direction: column;
            }
        }
    }
    &__label {
        display: block;
        font-size: 16px;
        font-weight: 500;
        margin-bottom: 16px;
    }
    &__input {
        font-weight: 500;
        border-bottom: solid 2px var(--black);
        padding-block-end: 10px;
        font-size: 20px;
        width: 100%;
        &::placeholder {
            font-family: "Urbanist", Arial;
            font-size: 20px;
        }
        &_error {
            border-color: var(--error);
        }
    }

    &__msg {
        border-bottom: solid 2px var(--black);
        padding-block-end: 0px;
        font-weight: 500;
        font-size: 20px;
        width: 100%;
        overflow: hidden;
        padding-bottom: 10px;
        &::placeholder {
            font-family: "Urbanist", Arial;
            font-size: 25px;
        }
        &_error {
            border-color: var(--error);
        }
    }
    &__select {
        padding-left: 0;
        background-color: transparent;
        border: none;
        border-bottom: solid 2px var(--black);
        width: 100%;
        font-size: 20px;
        font-weight: 500;
        padding-block-end: 10px;
        font-family: "Urbanist", Arial;
    }

    &__checkmark {
        height: 28px;
        min-width: 28px;
        border: 1px solid var(--black);
        padding: 2px;

        &_error {
            border-color: var(--error);
        }
    }

    &__terms {
        display: flex;
        gap: 20px;
        align-items: center;
        font-size: 16px;
        font-weight: 500;
        cursor: pointer;
        a {
            color: var(--purple);
        }
        &-input {
            display: none;

            &:checked ~ .form__checkmark:after {
                content: "";
                display: inline-block;
                color: var(--white);
                background-color: var(--purple);
                height: 100%;
                width: 100%;
            }
        }
    }

    &__send-btn {
        display: flex !important;
        text-align: center;
        margin: 0 auto 0 auto;
        color: var(--white);
        padding: 16px 32px;
        &_loading {
            pointer-events: none;
        }
    }

    &__error-text {
        font-size: 1rem;
        color: var(--error);
    }

    &__success-text {
        font-size: 1rem;
        color: var(--green);
    }
}
