.an {
  &_fade {
    opacity: 0;
    transition: opacity 1s;
  }
  &_left {
    opacity: 0;
    transform: translate(-200px);
    transition: transform 1s, opacity 1s;
  }
  &_right {
    opacity: 0;
    transform: translate(200px);
    transition: transform 1s, opacity 1s;
  }
  &_bottom {
    opacity: 0;
    transform: translate(0, 200px);
    transition: transform 1s, opacity 1s;
  }
  &_top{
    opacity: 0;
    transform: translate(0, -200px);
    transition: transform 1s, opacity 1s;
  }
  &_show {
    &_fade {
      opacity: 1;
    }
    &_left {
      transform: translate(0);
      opacity: 1;
    }
    &_right {
      transform: translate(0);
      opacity: 1;
    }
    &_bottom {
      transform: translate(0);
      opacity: 1;
    }
    &_top {
      transform: translate(0);
      opacity: 1;
    }
  }
}
