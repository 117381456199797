@import "../../styles/mixins.scss";

.footer {
  background-color: var(--white);
  color: var(--black);
  &__wrap {
    padding-block-start: 80px;
    background-image: url("../../assets/contact/contact_us_1.png");
    background-position: left bottom;
    background-repeat: no-repeat;
    &_bg {
      &_2 {
        background-image: url("../../assets/contact/contact_us_2.png");
      }
      &_3 {
        background-image: url("../../assets/contact/contact_us_3.png");
      }
    }
    @include lg {
      padding-block-start: 40px;
      background-image: initial;
    }
  }
  &__form {
    background-color: var(--white);
    width: 50%;
    margin-left: auto;
    @include lg {
      width: 100%;
    }
    &-text {
      max-width: 430px;
      margin: auto auto 32px auto;
    }
    &-title {
      text-align: center;
      font-weight: 900;
      font-size: 48px;
      line-height: 52px;
      margin-bottom: 8px;
      @include lg {
        font-size: 32px;
        line-height: 32px;
      }
    }
    &-desc {
      font-size: 16px;
      font-weight: 500;
      line-height: 19px;
      text-align: center;
    }
  }
}

.brand {
  &__link {
    color: var(--black);
  }
}
