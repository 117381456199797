@import '../../styles/mixins.scss';

.header {
  position: sticky;
  top: 0;
  background-color: transparent;
  background-color: var(--black);
  z-index: 200;

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0.875rem 0;
  }

  nav {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    overflow: hidden;
    flex-grow: 1;
    flex-shrink: 0;
  }

  ul {
    display: flex;
    align-items: center;
    gap: 56px;
    list-style-type: none;
    padding: 0;
    margin: 0;
  }
}

.nav-link {
  position: relative;
  color: var(--white);
  font-size: 16px;
  transition: color 0.5s ease;
  padding-block-end: 2px;
  font-weight: 900;
  text-transform: uppercase;
  &::before {
    content: '';
    display: block;
    position: absolute;
    bottom: 0;
    left: 50%;
    transform: translateX(-50%) scale(0);
    transform-origin: center center;
    width: 100%;
    height: 1px;
    background-color: #CDCDCD;
    transition: transform .5s ease;

    @media screen and (max-width: 992px) {
      transform-origin: left;
    }
  }

  &:hover,
  &:focus,
  &:active,
  &_active {
    &::before {
      transform: translateX(-50%) scale(1);
    }
  }
}

.nav-item {
  display: block;
}

.bars {
  display: none;
  width: 35px;
  height: 5px;
  background-color: var(--white);
  margin-bottom: 3px;
  transition: 0.5s ease;

  @media screen and (max-width: 992px) {
    display: block;
  }
}

.header_content {
  @media screen and (max-width: 992px) {
    position: fixed;
    padding-block-start: 4rem;
    top: 70px;
    right: 0;
    width: 100%;
    height: 100vh;
    background-color: var(--black);
    transform: translateX(200%);
    transition: transform .5s ease;
    padding: 2rem 2rem 0;

    ul {
      flex-wrap: wrap;
      align-items: flex-start;
      gap: 1rem;

      li {
        flex: 0 0 100%;
      }
    }
  }
}

.burger_bar_container {
  cursor: pointer;
  position: absolute;
  z-index: 1000;
  display: flex;
  flex-direction: column;
  align-items: flex-end;

  &.active .bar1 {
    transform: rotate(-45deg) translate(-4px, 8px);
  }

  &.active .bar2 {
    opacity: 0;
  }

  &.active .bar3 {
    transform: rotate(45deg) translate(-3px, -8px);
  }

  &.active+.header_content {
    transform: translateX(0);
    z-index: 2;
  }
}

.header_brand {
  max-width: 94px;
}

.header_brand_txt {
  align-self: flex-end;
  margin: 0.2rem;
  font-size: 0.825rem;
  line-height: 1;

}

@media screen and (max-width: 1200px) {
  .header_container {
    height: 90px;
    padding: 0 3.125rem;

    nav {
      justify-content: flex-end;
    }
  }
}

@media screen and (max-width: 768px) {
  .header_container {
    padding: 0 1.875rem;
  }
}