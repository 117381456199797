@import "../../styles/mixins.scss";

.intro {
    background-color: var(--white);
    background-image: url("../../assets/intro/bg.png");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: bottom;
    &_team {
        background-image: url("../../assets/team/bg.png");
    }
    &_services {
        background-image: url("../../assets/services/bg.png");
    }
    &__icon {
        width: 79px;
        margin: auto;
        padding-bottom: 28px; 
    }
}
