@import "./mixins.scss";
@font-face {
  font-family: "CheddarGothic-sans";
  src: url("./fonts/cheddargothic-sans-webfont.woff2") format("woff2"),
    url("./fonts/cheddargothic-sans-webfont.woff") format("woff"),
    url("./fonts/cheddargothic-sans-webfont.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

@font-face {
  font-family: "Poppins";
  src: url("./fonts/Poppins-Regular.woff2") format("woff2"), url("./fonts/Poppins-Regular.woff") format("woff"),
    url("./fonts/Poppins-Regular.ttf") format("truetype");
  font-weight: 400;
  font-display: swap;
  font-style: normal;
}

:root {
  --primary: #9721ec;
  --purple: #7029cb;
  --light-purple: #bf8dff;
  --black: #080604;
  --black-sec: #070707;
  --white: #ffffff;
  --green: #47ac94;
  --dark-green: #00222a;
  --error: #ff6c6c;
  --violet: #6d5dff;
  --surface: #ebebeb;
  --dark-gray: #313131;
  --light-gray: #cdcdcd;
  --black-medium: #202020;
  --bg-gradient: linear-gradient(to top, #0b0b0b 50%, #9721ec 150%);
  --btn-border: #8a38e3;
  --swiper-navigation-color: var(--purple);
}

html {
  scroll-behavior: smooth;
}

html,
body {
  padding: 0;
  margin: 0;
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
  font-size: 16px;
  font-weight: 400;
  background-color: var(--black);
  color: var(--white);
}

.slider-testominals {
  .slider-testominals__star {
    width: 50px;
    color: var(--purple);
    position: absolute;
  }
  .swiper-slide-active {
    .slider-testominals__container {
      padding-right: 10px;
      @include lg {
        padding-right: 60px;
        padding-top: 20px;
        padding-bottom: 0px;
      }
    }
    .slider-testominals__star {
      left: 0;
      bottom: 0;
      transform: translate(-50%, 50%);
    }
  }
  .swiper-slide-next {
    .slider-testominals__container {
      padding-left: 10px;
    }
    .slider-testominals__star {
      right: 0;
      bottom: 0;
      transform: translate(50%, 50%);
    }
  }
}

.slider {
  --swiper-navigation-color: var(--white);
}

.mb-2 {
  margin-bottom: 20px;
}

button {
  font-family: "Urbanist", -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans,
    Droid Sans, Helvetica Neue, sans-serif;
}


* {
  box-sizing: border-box;
}

section {
  overflow-y: hidden;
}

img {
  max-width: 100%;
  height: auto;
}

a {
  color: inherit;
  text-decoration: none;
}
h1,
h2,
h3,
h4,
h5,
h6,
p {
  margin-block-start: 0;
}

h1,
.h1 {
  font-size: 64px;
  font-weight: 900;
  line-height: 76px;
  margin-bottom: 16px;
  @include lg {
    font-size: 42px;
    line-height: normal;
  }
}

h2,
.h2 {
  font-size: 100px;
  line-height: 120px;
  font-weight: 900;
  @include lg {
    font-size: 64px;
    line-height: 64px;
  }
}

h3,
.h3 {
  font-size: 1.875rem;
  line-height: 36px;
}

h4,
.h4 {
  font-size: 1.5625rem;
  line-height: 30px;
  font-weight: 400;
}

h6,
.h6 {
  font-size: 1.125rem;
  line-height: 22px;
}

p {
  font-size: 1.5rem;
  line-height: 29px;
  font-weight: 400;
}

.fw-normal {
  font-weight: 400;
}

.spacing-xl {
  padding-block-start: 10rem;
  padding-block-end: 10rem;
}

.spacing {
  padding-block-start: 5rem;
  padding-block-end: 5rem;
}

.pt-xl {
  padding-block-start: 10rem;
}

.pb-xl {
  padding-block-end: 10rem;
}

.pt {
  padding-block-start: 5rem;
}

.pb {
  padding-block-end: 5rem;
}

.m-0 {
  margin: 0;
}

.px-container {
  padding-inline-start: 2.5rem;
  padding-inline-end: 2.5rem;

  @media screen and (max-width: 567px) {
    padding-inline-start: 1.875rem;
    padding-inline-end: 1.875rem;
  }
}

.mx-auto {
  margin-inline-start: auto;
  margin-inline-end: auto;
}

.mb-4 {
  margin-block-end: 3.75rem;
}

.mb-6 {
  margin-block-end: 5rem;
}

.text-uppercase {
  text-transform: uppercase;
}

input,
button,
textarea {
  appearance: none;
  -webkit-appearance: none;
  border: none;
  outline: 0;
  color: inherit;
  background-color: transparent;
  padding: 0;
  margin: 0;
  resize: vertical;

  &:hover,
  &:active,
  &:focus {
    box-shadow: none;
    outline: 0;
    background-color: transparent;
  }
}

.text--gray {
  color: var(--light-gray);
}

.color--primary {
  color: var(--primary);
}

.border-b {
  --section-border: var(--white);
  border-bottom: 4px solid var(--section-border);
}

.btn--dark {
  display: block;
  width: 198px;
  height: 70px;
  line-height: 68px;
  flex-shrink: 0;
  text-align: center;
  background-color: transparent;
  border: 1px solid var(--primary);
  color: var(--white);
  border-radius: 25px;
  font-size: 1rem;
  transition: background-color 0.5s ease, color 0.5s ease;

  &:hover {
    background-color: var(--primary);
  }
}

.text-center {
  text-align: center;
}

.home--slider-wrapper {
  position: relative;
  margin-block-end: 3rem;

  &::before,
  &::after {
    content: "";
    display: block;
    position: absolute;
    top: 18%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 450px;
    height: 450px;
    transform: scale(0.8);
    z-index: 100;
    pointer-events: none;

    @media screen and (max-width: 992px) {
      content: none;
    }
  }

  .swiper-button-next,
  .swiper-button-prev {
    display: none;
    width: 50px;
    height: 50px;
    line-height: 50px;
    text-align: center;
    background-color: rgba(255, 255, 255, 0.8);
    color: var(--black);
    border-radius: 100%;

    &::after {
      font-size: 2rem;
      font-weight: bold;
      vertical-align: middle;
    }

    @media screen and (max-width: 992px) {
      display: block;
    }

    @media screen and (max-width: 567px) {
      top: 30%;
    }
  }

  &::before {
    background-image: url("../assets/left.png");
    left: -90px;
  }

  &::after {
    background-image: url("../assets/right.png");
    right: -90px;
  }
}

.home--slider {
  position: relative;
  height: 800px;

  @media screen and (max-width: 992px) {
    width: 100%;
    height: 700px;
  }

  @media screen and (max-width: 567px) {
    height: 600px;
  }

  .swiper-wrapper {
    align-items: center;
  }

  .swiper-slide {
    width: 400px;
    height: 500px;
    transform: scale(0.8);
    transition: transform 0.3s ease;

    @media screen and (max-width: 992px) {
      width: auto;
      height: 600px;

      & > div:not(.brackets) {
        min-width: 320px;
        max-width: 375px;
        width: 100%;
        margin: 0 auto;
      }
    }

    &-active {
      transform: scale(1);

      .brackets {
        opacity: 1;
        transition: transform 1s ease, opacity 0.5s ease;
        transform: translateX(0);

        @media screen and (max-width: 992px) {
          display: none;
        }

        svg {
          transition-delay: 2s;
          transition: transform 0.5s ease;
          transform: rotate(-45deg);
        }

        &.brackets_bottom {
          svg {
            transform: rotate(135deg);
          }
        }
      }

      > div > div {
        opacity: 1;
        pointer-events: all;

        h3,
        h4 {
          transform: translateY(0);
        }

        a {
          opacity: 1;

          &:nth-child(2) {
            transition-delay: 0.5s;
          }

          &:nth-child(3) {
            transition-delay: 0.7s;
          }
        }
      }
    }

    img {
      max-width: 100%;
      height: 400px;
      object-fit: cover;

      @media screen and (max-width: 992px) {
        height: 450px;
      }

      @media screen and (max-width: 567px) {
        height: 350px;
      }
    }
  }
}

.btn-primary {
  display: block;
  width: 100%;
  height: 124px;
  max-width: 600px;
  min-width: 192px;
  border: 1px solid var(--white);
  background-color: var(--white);
  color: var(--black);
  font-size: 1.875rem;
  line-height: 122px;
  transition: background-color 0.5s ease, color 0.2s ease;
  text-transform: uppercase;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;

  h2 {
    font-size: 1.875rem;
    font-weight: 400;
  }

  &:hover {
    background-color: transparent;
    color: var(--white);
  }

  @media screen and (max-width: 567px) {
    height: 90px;
    line-height: 88px;
  }
}

.btn-full-width {
  max-width: 100%;
}

.sticky--section {
  &-wrap {
    position: relative;
    display: flex;
    justify-content: space-between;

    @include md {
      flex-direction: column;
      justify-content: flex-start;
    }
  }

  &-bar {
    --sticky-radius: 100%;
    --sticky-color: var(--light-gray);
    --sticky-transform: 0deg;
    position: sticky;
    top: 120px;
    display: flex;
    flex: 0 0 30%;
    align-items: center;
    align-self: flex-start;
    text-transform: uppercase;
    padding-block-start: 2.3rem;
    color: var(--sticky-color);

    @include md {
      position: initial;
      margin-bottom: 8px;
    }

    &::before {
      content: "";
      display: block;
      width: 22px;
      height: 22px;
      border-radius: var(--sticky-radius);
      background-color: var(--sticky-color);
      transform: rotate(var(--sticky-transform));
      margin-inline-end: 20px;
    }
  }

  @media screen and (max-width: 768px) {
    position: relative;
    top: 0;
    flex: 0 0 100%;
    padding-block-start: 0;
  }
}

.sticky--section-content {
  flex: 0 0 60%;

  @media screen and (max-width: 768px) {
    flex: 0 0 100%;
  }
}

.container {
  margin: 0 auto;
  max-width: 1320px;
  @include xxl {
    padding: 0 2rem;
    overflow: hidden;
  }
}


.green-text {
  color: var(--green);
}

.violet-text {
  color: var(--violet);
}

.active-link {
  &::before {
    background-color: red;
  }
}

.fade {
  &-enter {
    opacity: 0;
    transform: translateX(-20px);
  }

  &-exit {
    opacity: 1;
    transform: translateX(0);
  }

  &-exit.fade-exit-active {
    opacity: 0;
    transform: translateX(-20px);
    transition: opacity 600ms ease-out, transform 600ms ease-out;
  }

  &-enter.fade-enter-active {
    opacity: 1;
    transform: translateX(0);
    transition: opacity 600ms ease-out, transform 600ms ease-out;
  }
}
